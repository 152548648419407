<template></template>

<script>
export default {
    created() {
        this.$router.replace({ path: '/' })
    }
}
</script>

<style></style>